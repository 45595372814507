// extracted by mini-css-extract-plugin
export var actionContainer = "styles-module--actionContainer--3A81B";
export var highlight = "styles-module--highlight--4y2Mw";
export var highlightSeparator = "styles-module--highlightSeparator---v8iA";
export var highlightsContainer = "styles-module--highlightsContainer--8L1Nz";
export var highlightsHeader = "styles-module--highlightsHeader--iDAft";
export var icon = "styles-module--icon--QV+ks";
export var planSummaryContainer = "styles-module--planSummaryContainer--P4kR1";
export var planSummaryContainerPopular = "styles-module--planSummaryContainerPopular--7B23j";
export var popular = "styles-module--popular--+xD16";
export var priceContainer = "styles-module--priceContainer--AZy+v";
export var priceDollar = "styles-module--priceDollar--dLgAx";
export var priceLabel = "styles-module--priceLabel--e3tMC";
export var priceMsg = "styles-module--priceMsg--9J+5-";
export var pricePeriod = "styles-module--pricePeriod--7fRFM";
export var priceValue = "styles-module--priceValue--WvZYl";
export var priceValueRow = "styles-module--priceValueRow--62hMT";
export var promitionsContainer = "styles-module--promitionsContainer--flbk2";
export var title = "styles-module--title--9DTcQ";