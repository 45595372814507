import * as _ from 'lodash'
import React, { Fragment } from 'react'

import cx from 'classnames'

import * as styles from './styles.module.css'
import * as buttonStyles from '../button-styles.module.css'

import { getPlanDetails } from '../../../../content/data/pricingPage';

export const OssSupported = () => {

    const planId = 'kubevious-supported';
    const planDetails = getPlanDetails(planId);

    return <div className={styles.container}>
        
        <div className={styles.title}>
            <div className={styles.icon}
                 style={{ backgroundImage: `url('${planDetails.icon}')` }}
                 />
            <span style={{ color: planDetails.accentColor }} >
                {planDetails.name}
            </span>
        </div>

        {planDetails.infoMessage &&
            <div className={styles.infoMessage}>
                {planDetails.infoMessage}
            </div>
        }

        {planDetails.price &&
            <div className={styles.pricing}>

                <div className={styles.priceLabel}>
                    Starting<br/> from
                </div>
                <div className={styles.priceBlock}>
                    <div className={styles.priceDollar}>
                        $
                    </div>
                    <div className={styles.priceValue}>
                        {planDetails.price.value}
                    </div>
                    <div className={styles.pricePeriod}>
                        /mo
                    </div>
                </div>
                <div className={styles.priceLabel}>
                    per<br/>node
                </div>

            </div>
        }

        <a className={buttonStyles.priceButton}
            href='/meeting/open-source-support-request/'>
            Lets Talk!
        </a>

    </div>
}