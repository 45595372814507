import * as _ from 'lodash'
import React, { useState } from 'react'

import cx from 'classnames'

import * as styles from './styles.module.css'


export const FAQ = ({faq}) => {

    const [isExpanded, setIsExpanded] = useState(false);

    const expandCollapseClick = () => {
        setIsExpanded(!isExpanded);
    }

    return <div className={styles.container}>
        
        <div className={styles.question}
             onClick={expandCollapseClick}>
            <span>{faq.question}</span>
            <div className={cx(styles.btn, {[styles.btnExpanded] : isExpanded})} ></div>
        </div>

        <div className={cx(styles.answer, {[styles.answerHidden] : !isExpanded})}>
            {faq.answer}
        </div>

    </div>
}