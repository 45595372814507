// extracted by mini-css-extract-plugin
export var container = "styles-module--container--g3TuG";
export var content = "styles-module--content--PDVFd";
export var contentBackground = "styles-module--contentBackground---fg7U";
export var contentBottomPadding = "styles-module--contentBottomPadding--RUyhL";
export var devTitle = "styles-module--devTitle--q-tdm";
export var headerWrapper = "styles-module--headerWrapper--SDUZb";
export var iconWrapper = "styles-module--iconWrapper--E6Ysy";
export var subTitle = "styles-module--subTitle--AFQiO";
export var title = "styles-module--title--E1hLJ";
export var titleNormalColor = "styles-module--titleNormalColor--gcKWL";
export var titleWaveColor = "styles-module--titleWaveColor--gbUV-";
export var titles = "styles-module--titles--sLRma";