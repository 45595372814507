import React, { Fragment } from 'react'

import cx from 'classnames'

import * as styles from './styles.module.css'

import { getPlanDetails } from '../../../../content/data/pricingPage';
import { FeatureMatrixValue } from '../feature-matrix-value';

export const FeatureMatrix = ({matrix}) => {

    return (
        <div className={styles.featureMatrixContainer}>
            <div className={styles.title}>{matrix.title}</div>

            <table className={styles.pricingTable}>
                <thead>
                    <tr>
                        <th></th>
                        {matrix.plans.map((plan, index) => {
                            const planDetails = getPlanDetails(plan);
                            return <th key={index}>
                                <div className={styles.headerBlock}>
                                    <div className={styles.headerIcon}
                                        style={{ backgroundImage: `url('${planDetails.icon}')` }}
                                        />
                                    <div className={styles.headerTitle}
                                          style={{ color: planDetails.accentColor }} >
                                        {planDetails.name}
                                    </div>
                                </div>
                            </th>
                        })}
                    </tr>
                </thead>
                <tbody>
                    {matrix.features.map((feature, index) => (
                        <tr key={index}>
                            <td className={styles.featureTitle}>{feature.name}</td>
                            {matrix.plans.map((plan, index2) => {
                                const value = feature.values[plan];
                                return <td key={index2}>
                                    <FeatureMatrixValue value={value} />
                                </td>
                            })}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}