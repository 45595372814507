export const PRICING = {
    mainTitle: 'Build better Kubernetes experiences.',
    subTitle: 'Choose your path to become a Kubernetes Ninja!',

    plans: {
        kubevious: {
            icon: '/img/pricing/kubevious.svg',
            name: 'Open Source',
            accentColor: '#74275B',
        },
        'kubevious-star': {
            icon: '/img/pricing/kubevious.svg',
            name: 'Open-Source *',
            accentColor: '#74275B',
        },
        'kubevious-supported': {
            icon: '/img/pricing/kubevious.svg',
            name: 'Open-Source Supported *',
            accentColor: '#74275B',
            infoMessage: 'Need help running our open-source project?'
            // price: {
            //     value: '5'
            // }
        },
        ronin: {
            icon: '/img/pricing/ronin.svg',
            name: 'Ronin',
            accentColor: '#557C9B',
        },
        samurai: {
            icon: '/img/pricing/samurai.svg',
            name: 'Samurai',
            accentColor: '#5C7A44',
        },
        shogun: {
            icon: '/img/pricing/shogun.svg',
            name: 'Shogun',
            accentColor: '#C50202',
        },
    },
    

    plansSummary: [
        {
            id: 'kubevious',
            price: {
                msg: 'Free'
            },
            highlights: [
                "Application centric UI",
                "Configuration validation",
                "Time Machine",
                "Search Engine",
            ],
            action: {
                label: 'Install Now!',
                link: '/open-source'
            }
        },
        {
            id: 'ronin',
            popular: true,
            price: {
                msg: 'Start for Free'
                // value: '12'
            },
            highlightsHeader: 'everything in <br />Open-Source, plus:',
            highlights: [
                "Cloud dashboard",
                "Multiple clusters",
                "Slack notifications & Bot",
                "Team support",
            ],
            action: {
                label: 'Try it for free!',
                link: 'https://portal.kubevious.io'
            },
            // promotions: [
            //     { count: 14, label: 'days free'},
            //     { count: 5, label: 'nodes free'},
            // ]
        },
        {
            id: 'samurai',
            price: {
                msg: 'Request'
            },
            highlightsHeader: 'everything in Ronin, plus:',
            highlights: [
                "Image vulnerability scan",
                "Continuous security validation",
                "Reporting",
            ],
            action: {
                label: 'Let\'s Talk',
                link: '/meeting/plan-samurai-request/'
            }
        },
        {
            id: 'shogun',
            price: {
                msg: 'Request'
            },
            highlightsHeader: 'everything in Samurai, plus:',
            highlights: [
                "Run in your premises",
                "SSO/SAML",
                "Unlock everything",
            ],
            action: {
                label: 'Let\'s Talk',
                link: '/meeting/plan-shogun-request/'
            }
        },
    ],

    promotions: [
        // "First 14-days free",
        // "First 5 servers free",
        "No credit card required to sign up"
    ],

    featureMatrixes: [
        {
            title: 'SRE/Ops Core Functionality',
            plans: ['kubevious', 'ronin', 'samurai', 'shogun'],
            features: [
                {
                    name: 'Clusters',
                    values: {
                        kubevious: 1,
                        ronin: 'up to 5',
                        samurai: 'up to 15',
                        shogun: 'unlimited',
                    }
                },
                {
                    name: 'Continuous Validation',
                    values: {
                        kubevious: true,
                        ronin: true,
                        samurai: true,
                        shogun: true,
                    }
                },
                {
                    name: 'Custom Rules',
                    values: {
                        kubevious: true,
                        ronin: 'yes + multi-cluster',
                        samurai: 'yes + multi-cluster',
                        shogun: 'yes + multi-cluster',
                    }
                },
                {
                    name: 'Search',
                    values: {
                        kubevious: true,
                        ronin: 'yes + multi-cluster',
                        samurai: 'yes + multi-cluster',
                        shogun: 'yes + multi-cluster',
                    }
                },
                {
                    name: 'Time Machine',
                    values: {
                        kubevious: true,
                        ronin: true,
                        samurai: true,
                        shogun: true,
                    }
                },
            ]
        },

        {
            title: 'Proactive and Forensics',
            plans: ['kubevious', 'ronin', 'samurai', 'shogun'],
            features: [
                {
                    name: 'Notifications',
                    values: {
                        kubevious: false,
                        ronin: 'slack',
                        samurai: 'slack',
                        shogun: 'slack, Pager Duty',
                    }
                },
                {
                    name: 'Change History',
                    values: {
                        kubevious: false,
                        ronin: true,
                        samurai: true,
                        shogun: true,
                    }
                },
                {
                    name: 'Report Generation',
                    values: {
                        kubevious: false,
                        ronin: false,
                        samurai: true,
                        shogun: true,
                    }
                },
            ]
        },

        {
            title: 'Security',
            plans: ['kubevious', 'ronin', 'samurai', 'shogun'],
            features: [
                {
                    name: 'Continuous CIS Benchmark Validation',
                    values: {
                        kubevious: false,
                        ronin: false,
                        samurai: true,
                        shogun: true,
                    }
                },
                {
                    name: 'Continuous Vulnerability Scanning',
                    values: {
                        kubevious: false,
                        ronin: false,
                        samurai: 'only public repos from dockerhub and quay',
                        shogun: true,
                    }
                },
            ]
        },

        {
            title: 'Team',
            plans: ['kubevious', 'ronin', 'samurai', 'shogun'],
            features: [
                {
                    name: 'Users',
                    values: {
                        kubevious: false,
                        ronin: 'up to 3',
                        samurai: 'up to 10',
                        shogun: 'unlimited',
                    }
                },
                {
                    name: 'RBAC',
                    values: {
                        kubevious: false,
                        ronin: false,
                        samurai: true,
                        shogun: true,
                    }
                },
                {
                    name: 'SAML SSO',
                    values: {
                        kubevious: false,
                        ronin: false,
                        samurai: false,
                        shogun: true,
                    }
                },
            ]
        },

        {
            title: 'Processing',
            plans: ['kubevious', 'ronin', 'samurai', 'shogun'],
            features: [
                {
                    name: 'Data Retention',
                    values: {
                        kubevious: '2 weeks',
                        ronin: '3 months',
                        samurai: '6 months',
                        shogun: 'unlimited',
                    }
                },
                {
                    name: 'Runtime',
                    values: {
                        kubevious: 'DIY in your cluster',
                        ronin: 'Kubevious Cloud',
                        samurai: 'Kubevious Cloud',
                        shogun: 'Kubevious Cloud or Your Premises',
                    }
                },
                {
                    name: 'Statistical Accuracy',
                    values: {
                        kubevious: '5 mins',
                        ronin: '5 mins',
                        samurai: '3 mins',
                        shogun: '< 1min',
                    }
                },
            ]
        },


        {
            title: 'Support',
            plans: ['kubevious-star', 'ronin', 'samurai', 'shogun'],
            features: [
                {
                    name: 'Bug fixes',
                    values: {
                        ['kubevious-star']: 'OSS community',
                        ronin: true,
                        samurai: true,
                        shogun: true,
                    }
                },
                {
                    name: 'Feature requests',
                    values: {
                        ['kubevious-star']: 'OSS community',
                        ronin: true,
                        samurai: true,
                        shogun: true,
                    }
                },
                {
                    name: 'General help',
                    values: {
                        ['kubevious-star']: 'Community slack',
                        ronin: true,
                        samurai: true,
                        shogun: true,
                    }
                },
            ]
        },

    ]

}

export const PER_NODE_TABLE = [
    {
        cpu: 8,
        price: 12,
    },
    {
        cpu: 16,
        price: 24,
    },
    {
        cpu: 32,
        price: 48,
    },
    {
        cpu: 48,
        price: 36,
    },
    {
        cpu: 64,
        price: 96,
    },
    {
        cpu: 128,
        price: 192,
    },
]

export const FAQ = [
    {
        question: "There are so many K8s tools, why would I need Kubevious?",
        answer: "Kubernetes ecosystem is powerful, yet very complex. Kubevious exposes application-level configuration insights, detects misconfigurations, conflicts, and violations to best-practices, and is packed with Cloud-Native tools - all from a single cloud dashboard. Kubevious lets K8s users make smarter and safer decisions and aids with troubleshooting when issues arise. That's quite unique, isn't it? Try it, you will love it!"
    },
    {
        question: "Can I try Kubevious before I buy?",
        answer: "Absolutely! You can sign up for Kubevious Ronin plan for a free trial. No credit card is required. Also, Kubevious open-source project is free forever."
    },
    {
        question: "What plan will I be on when I sign up?",
        answer: "Your project will be put on our Ronin plan when you sign up. Book a consultation with our team if you are interested in Samurai or Shogun plans."
    },
    {
        question: "How long does Kubevious store my data?",
        answer: "Kubevious Open-Source project retains your data for 14 days. Each plan has its own data retention period. For example, you can access the history of cluster changes for up to 3 months using the Ronin plan."
    },
];

export function getPlanDetails(name)
{
    return PRICING.plans[name];
}