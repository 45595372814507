import React from 'react'

import * as styles from './styles.module.css'

export const Promotion = ({promo}) => {
    return <div className={styles.promoContainer}>

        <div className={styles.promoTick} >
        </div>

        <div className={styles.promoText} >
            {promo}
        </div>

    </div>
}

