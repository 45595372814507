import * as _ from 'lodash'
import React, { Fragment } from 'react'

import cx from 'classnames'

import * as styles from './styles.module.css'

import { FAQ } from '../../../../content/data/pricingPage';

import { FAQ as FAQComponent } from '../faq';

export const FAQs = () => {

    return <div className={styles.container}>
        
        <div className={styles.title}>
            FAQ
        </div>

        <div className={styles.items}>
            {FAQ.map((faq, index) => 
                <FAQComponent key={index} faq={faq}></FAQComponent>
            )}
        </div>
    </div>

}