// extracted by mini-css-extract-plugin
export var container = "styles-module--container--Ua6LF";
export var icon = "styles-module--icon--Ds2fn";
export var infoMessage = "styles-module--infoMessage--+s5zB";
export var priceBlock = "styles-module--priceBlock--40jdn";
export var priceDollar = "styles-module--priceDollar--uKogi";
export var priceLabel = "styles-module--priceLabel--kpwsx";
export var pricePeriod = "styles-module--pricePeriod--+xb+s";
export var priceValue = "styles-module--priceValue--yZ-yI";
export var pricing = "styles-module--pricing--h2jWQ";
export var title = "styles-module--title--LMRKv";