import React from 'react'

import * as styles from './styles.module.css'

import { PRICING } from '../../../../content/data/pricingPage'
import { PlanSummary } from '../plan-summary';

export const PlansSummary = () => {

    return <div className={styles.plansSummary}>
        {PRICING.plansSummary.map((x, index) => (
            <PlanSummary key={index} planInfo={x} />
        ))}
    </div>
}