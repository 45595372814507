import * as _ from 'lodash'
import React, { Fragment } from 'react'

import cx from 'classnames'

import * as styles from './styles.module.css'
import * as buttonStyles from '../button-styles.module.css'

export const BookConsultation = () => {

    return <div className={styles.container}>
        
        <div className={styles.icon}>

        </div>

        <div className={styles.body}>
            <div className={styles.msg}>
                Book a 30-minute consultation today to explore how You can run Your Kubernetes apps faster, easier and safer!
            </div>

            <a className={cx(buttonStyles.priceButton, styles.consultationButton)}
                href='/meeting/plan-consultation-request/'>
                Book Consultation
            </a>
        </div>

    </div>
}