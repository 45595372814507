import * as _ from 'lodash'
import React from 'react'

import * as styles from './styles.module.css'


export const PromotionBox = ({ count, label }) => {

    return <div className={styles.container}>
        
        <div className={styles.counter}>
            {count}
        </div>

        <div className={styles.label}>
            {label}
        </div>

    </div>
}