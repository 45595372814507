import React, { Fragment } from 'react'

import cx from 'classnames'

import * as styles from './styles.module.css'
import * as buttonStyles from '../button-styles.module.css'

import { getPlanDetails } from '../../../../content/data/pricingPage';
import { PromotionBox } from '../promotion-box';

export const PlanSummary = ({planInfo}) => {
    const planDetails = getPlanDetails(planInfo.id);

    return <div className={cx(styles.planSummaryContainer, { [styles.planSummaryContainerPopular]: planInfo.popular })}>

        {planInfo.popular && 
            <div className={styles.popular}>
            </div>
        }

        <div className={styles.title}
             >
            <div className={styles.icon}
                 style={{ backgroundImage: `url('${planDetails.icon}')` }}
                 />
            <span style={{ color: planDetails.accentColor }} >
                {planDetails.name}
            </span>
        </div>

        <div className={styles.priceContainer}>
            {planInfo.price.msg && (
                <div className={styles.priceMsg}
                     style={{ color: planDetails.accentColor }}>
                    {planInfo.price.msg}
                </div>
            )}
            {planInfo.price.value && <>
                <div className={styles.priceLabel}>
                    starting from
                </div>
                <div className={styles.priceValueRow}>
                    <div className={styles.priceDollar}>
                        $
                    </div>
                    <div className={styles.priceValue}>
                        {planInfo.price.value}
                    </div>
                    <div className={styles.pricePeriod}>
                        /mo
                    </div>
                </div>
                <div className={styles.priceLabel}>
                    per node *
                </div>
            </>}
        </div>

        {planInfo.highlightsHeader && 
        <div className={styles.highlightsHeader}
            dangerouslySetInnerHTML={{__html: planInfo.highlightsHeader}}>
        </div>}

        <div className={styles.highlightsContainer}>
            
            {planInfo.highlights.map((x, index) => (
                <Fragment key={index}>
                    <div className={styles.highlight}>
                        {x}
                    </div>

                    {(index !== (planInfo.highlights.length -1)) &&
                        <div key={`sep-${index}`}
                             className={styles.highlightSeparator}
                             >
                        </div>
                    }
                </Fragment>
            ))}
        </div>

        {planInfo.promotions && 
            <div className={styles.promitionsContainer}>
                {planInfo.promotions.map((promo, index) => 
                    <PromotionBox key={index}
                        count={promo.count}
                        label={promo.label}
                        />
                )}
            </div>
        }

        <div className={styles.actionContainer}>

            <a className={buttonStyles.priceButton}
               href={planInfo.action.link}>
                {planInfo.action.label}
            </a>
        </div>


    </div>
}