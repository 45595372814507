import React, { useEffect } from 'react'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import Header from '../Header'
import SEO from '../SEO'
import Footer from '../Footer'
import cx from 'classnames'

import * as styles from './styles.module.css'

library.add(fab)

const Layout = ({
    title,
    devTitle,
    subTitle,
    sectionName,
    forceFullLogo,
    backgroundImg,
    seoTitle,
    seoDescription,
    seoUrl,
    seoImage,
    seoKeywords,
    seoType,
    author,
    jsonLDScript,
    children,
    hideFooter,
    titleBackground,
    skipBottomPadding
}) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <SEO
                description={seoDescription}
                title={seoTitle}
                url={seoUrl}
                image={seoImage}
                keywords={seoKeywords}
                author={author}
                type={seoType}
                jsonLDScript={jsonLDScript}
            />

            <div className={cx(styles.container)}>
                <div className={styles.headerWrapper}>
                    <Header
                        logoColor={'#1d2e58'}
                        colorSocial
                        showBox
                        forceFullLogo={forceFullLogo}
                        sectionName={sectionName}
                    />
                </div>

                <div className={cx(styles.content, {[styles.contentBottomPadding] : !skipBottomPadding})}>

                    {titleBackground && 
                        <div className={styles.contentBackground}>
                            <div className={styles.titles}>
                                {title && <h1 className={cx(styles.title, styles.titleWaveColor)}>{title}</h1>}

                                {devTitle && <h2 className={cx(styles.devTitle, styles.titleWaveColor)}>{devTitle}</h2>}

                                {subTitle && <h2 className={cx(styles.subTitle, styles.titleWaveColor)}>{subTitle}</h2>}
                            </div>
                        </div>
                    }
                    
                    {!titleBackground && 
                        <div className={styles.titles}>
                            {title && <h1 className={cx(styles.title, styles.titleNormalColor)}>{title}</h1>}

                            {devTitle && <h2 className={cx(styles.devTitle, styles.titleNormalColor)}>{devTitle}</h2>}

                            {subTitle && <h2 className={cx(styles.subTitle, styles.titleNormalColor)}>{subTitle}</h2>}
                        </div> 
                    }

                    {children}
                </div>

                <div className={styles.iconWrapper}>
                    <img src={backgroundImg} alt="message" />
                </div>
            </div>
            {!hideFooter && <Footer colorSocial />}
        </>
    )
}

export default Layout
