import React from 'react'
import { PRICING } from '../../../content/data/pricingPage'
import Layout from '../../components/Layout'

import '../../styles/inner-pages.css'
import * as styles from './styles.module.css'
import * as commonStyles from '../../styles/common-components.module.css'

import { PlansSummary } from '../../components/Pricing/plans-summary';
import { Promotion } from '../../components/Pricing/promotion';
import { FeatureMatrix } from '../../components/Pricing/feature-matrix';
import { OssSupported } from '../../components/Pricing/oss-supported';
import { BookConsultation } from '../../components/Pricing/book-consultation';
import { PerServerPricing } from '../../components/Pricing/per-server-pricing';
import { FAQs } from '../../components/Pricing/faqs';

const Page = () => {
    return (
        <Layout
            forceFullLogo
            seoTitle="Kubevious :: Pricing"
            seoDescription="Kubevious Pricing"
            seoImage="/screens/site-pricing.png"
            // title={PRICING.mainTitle}
            // devTitle={PRICING.subTitle}
            // titleBackground
        >
            <div className={styles.contentBackground}>
            </div>

            <div className={styles.titles}>
                <h1 className={styles.title}>{PRICING.mainTitle}</h1>
                <h2 className={styles.subTitle}>{PRICING.subTitle}</h2>
            </div>

            <div className={styles.container}>

                <PlansSummary />
                
                <div className={styles.promotionsContainer}>
                    {PRICING.promotions.map((x, index) => (
                        <Promotion key={index} promo={x} />
                    ))}
                </div>

                <div className={styles.featureMatrixesContainer}>
                    {PRICING.featureMatrixes.map((x, index) => (
                        <FeatureMatrix key={index} matrix={x} />
                    ))}
                </div>
                
                <OssSupported />

                <FAQs />

                <BookConsultation />

                {/* <PerServerPricing /> */}
                
            </div>
        </Layout>
    )
}

export default Page
