import * as _ from 'lodash'
import React, { Fragment } from 'react'

import cx from 'classnames'

import * as styles from './styles.module.css'

import { getPlanDetails } from '../../../../content/data/pricingPage';

export const FeatureMatrixValue = ({value}) => {

    if (_.isBoolean(value))
    {
        if (value) {
            return <div className={cx(styles.yesNo, styles.yes)}>
            </div>
        } else {
            return <div className={cx(styles.yesNo, styles.no)}>
            </div>
        }
    }

    if (_.isString(value))
    {
        return <>
            {value}
        </>
        
    }

    if (_.isNumber(value))
    {
        return <>
            {value.toString()}
        </>
        
    }

    return <>
        
    </>
}